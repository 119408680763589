import { FC } from 'react';
import { Contact } from '../interface/contact';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import ConfirmModal from '../components/commons/ConfirmModal';
export const DuplicateModal: FC<{ contact: Contact; onClose: () => void }> = ({ contact, onClose }) => (
  <ConfirmModal
    title="Duplicato segnalato"
    text={
      <Stack>
        La tua segnalazione di duplicato per il contatto
        <Typography fontWeight="bold">
          <br />
          {contact.nome} {contact.cognome}
        </Typography>
        <br />
        <Typography fontWeight="bold">{contact.codice_fiscale}</Typography>
        <br />
        È stata inviata correttamente.
        <br />
        Il team di amministrazione provvederà alla modifica.
      </Stack>
    }
    open
    confirmButtonText="OK"
    onConfirm={() => onClose()}
    onCancel={() => onClose()}
  />
);
