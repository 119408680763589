import axios, { AxiosResponse } from 'axios';
import { refreshAccessToken } from './refreshToken';
import { ISummaryData } from '../interface/summary';
import { getDigitalIncomes } from './getDigitalIncomes';
import { parseExternalEventsFromServer } from '../components/summary/utils/parseExternalEventsFromServer';
import { DonazioneLibera, ProdottoDonazione } from '../interface/digitalIncomes';
import { getInvoiceCount } from './getDonazioniCount';
import { defaultSummaryValues } from '../components/summary/lib/defaultSummaryValues';

const { REACT_APP_API_URL } = process.env;

export async function fetchSummary(token: string): Promise<ISummaryData> {
  await refreshAccessToken();
  try {
    const response: AxiosResponse<ISummaryData> = await axios.get(`${REACT_APP_API_URL}/rendicontazioni`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Se l'errore è un errore Axios, puoi ottenere il messaggio dalla risposta dell'errore
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        throw new Error(errorMessage);
      } else {
        // Se il messaggio non è disponibile nella risposta, gestisci come errore generico
        throw new Error('Errore nella chiamata API: ' + error.message);
      }
    } else {
      // Altrimenti, è un errore generico
      throw new Error('Errore sconosciuto: ' + error);
    }
  }
}

export async function getSummaryData(token: string) {
  try {
    const rendicontazione: ISummaryData = await fetchSummary(token);
    const digitalIncomes = await getDigitalIncomes(token);
    const invoiceCount = await getInvoiceCount(token);

    const _qtDistribuitiDigitale = digitalIncomes?.find((item) => item.tipologia === 'prodotto') as ProdottoDonazione;
    const _qtImportoContributiDigitali = digitalIncomes?.find((item) => item.tipologia === 'donazione_libera') as
      | DonazioneLibera
      | undefined;

    const qtDistribuitiDigitale = _qtDistribuitiDigitale?.quantita_totale_prodotti ?? 0;
    const qtContributiDigitali = _qtImportoContributiDigitali?.importo_totale_donazione_libera ?? 0;

    let formData;

    if (Object.values(rendicontazione).length !== 0) {
      formData = {
        ...rendicontazione,
        qt_piazze_esterne: parseExternalEventsFromServer(rendicontazione?.qt_piazze_esterne || ''),
        qt_date_piazze_esterne: parseExternalEventsFromServer(rendicontazione?.qt_date_piazze_esterne || ''),
        qt_distribuiti_digitale: qtDistribuitiDigitale,
        qt_contributi_digitale: qtContributiDigitali,
        qt_ricevute_digitali: invoiceCount.numero_donazioni,
      };
    } else {
      formData = {
        ...defaultSummaryValues,
        qt_piazze_esterne: parseExternalEventsFromServer(rendicontazione?.qt_piazze_esterne || ''),
        qt_date_piazze_esterne: parseExternalEventsFromServer(rendicontazione?.qt_date_piazze_esterne || ''),
        qt_distribuiti_digitale: qtDistribuitiDigitale,
        qt_distribuiti: qtDistribuitiDigitale,
        qt_contributi_digitale: qtContributiDigitali,
        tot_contributi: qtContributiDigitali,
        qt_ricevute_digitali: invoiceCount.numero_donazioni,
      };
    }
    return formData;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      // Se l'errore è un errore Axios, puoi ottenere il messaggio dalla risposta dell'errore
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        throw new Error(errorMessage);
      } else {
        // Se il messaggio non è disponibile nella risposta, gestisci come errore generico
        throw new Error('Errore nella chiamata API: ' + error.message);
      }
    } else {
      // Altrimenti, è un errore generico
      throw new Error('Errore sconosciuto: ' + error);
    }
  }
}
