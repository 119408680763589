/* eslint-disable no-console */
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useQuery } from 'react-query';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { searchContacts } from '../api/searchContacts';
import { useAuth } from '../components/auth/AuthProvider';
import { SkeletonContactList } from '../components/commons/Skeleton';
import { SearchCriteria } from '../interface/contact';
import { ContactCards } from './ContactCards';

export const ContactResults = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const searchCriteria: SearchCriteria = {
    nome: searchParams.get('nome') || '',
    cognome: searchParams.get('cognome') || '',
    citta: searchParams.get('citta') || '',
    codice_fiscale: searchParams.get('codice_fiscale') || '',
  };

  const { data, isLoading, error } = useQuery(
    ['contacts', searchCriteria],
    () => searchContacts(searchCriteria, token),
    {
      staleTime: 60 * 1000, // 1 min
      onError: (error: Error) => {
        toast.error(error.message);
      },
    },
  );

  return (
    <div className="container-main gap">
      <div>
        <h1>Risultati di ricerca</h1>
        <p className="subtitle">Compatibili con Nome, Cognome e Località</p>
      </div>

      {isLoading ? (
        <SkeletonContactList />
      ) : (
        <ContactCards searchCriteria={searchCriteria} contacts={data?.conCittaTarget} error={error} />
      )}

      <div>
        <h1>Altri risultati</h1>
        <p className="subtitle">Compatibili con Nome e Cognome</p>
      </div>

      {isLoading ? (
        <SkeletonContactList />
      ) : (
        <ContactCards
          error={error}
          searchCriteria={searchCriteria}
          contacts={data?.senzaCitta.concat(data?.altreCitta)}
        />
      )}

      <Button
        variant="contained"
        disabled={isLoading}
        fullWidth
        startIcon={<AddIcon />}
        onClick={() =>
          navigate(
            {
              pathname: '/create-contact',
              search: createSearchParams({
                nome: searchCriteria.nome,
                cognome: searchCriteria.cognome,
                codice_fiscale: searchCriteria.codice_fiscale || '',
              }).toString(),
            },
            {
              state: location.state?.receipt && { receipt: location.state?.receipt },
            },
          )
        }
      >
        Nuova anagrafica
      </Button>
    </div>
  );
};
