import React, { ReactNode } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, Box, CircularProgress } from '@mui/material';

type ConfirmModalProps = {
  open: boolean;
  title?: string;
  subtitle?: string;
  text?: string | ReactNode;
  backButtonText?: string;
  confirmButtonText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
};

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
  const {
    open,
    title,
    subtitle,
    text,
    backButtonText,
    confirmButtonText,
    onCancel = () => {},
    onConfirm = () => {},
    loading = false,
  } = props;

  return (
    <Dialog open={open} onClose={loading ? undefined : onCancel} style={{ maxWidth: '500px', margin: '0 auto' }}>
      {title && <DialogTitle textAlign="center">{title}</DialogTitle>}
      {subtitle && (
        <DialogTitle variant="h2" textAlign="center">
          {subtitle}
        </DialogTitle>
      )}
      {text && (
        <DialogContent style={{ paddingTop: 20, textAlign: 'center' }}>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
        {backButtonText && (
          <Button onClick={onCancel} disabled={loading} color="primary" variant="outlined">
            {backButtonText}
          </Button>
        )}
        {confirmButtonText && (
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            disabled={loading}
            startIcon={loading && <CircularProgress size="1rem" color="inherit" />}
          >
            {confirmButtonText}
          </Button>
        )}
      </Box>
    </Dialog>
  );
};

export default ConfirmModal;
