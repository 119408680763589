import { Chip, ChipOwnProps } from '@mui/material';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import { Donazione } from '../../interface/donazione';
import { useMemo } from 'react';

const voidFunc = () => void {};

interface DonazioneStatusChipProps {
  status: Donazione['status'];
  show?: boolean;
}

const getStatusIcon = (status: Donazione['status']): ChipOwnProps['deleteIcon'] => {
  switch (status) {
    case 'COMPLETED':
      return <CheckCircle />;
    case 'CANCELED':
    case 'PENDING':
    case 'ERROR':
      return <Cancel />;
  }
};

const getStatusColor = (status: Donazione['status']): ChipOwnProps['color'] => {
  switch (status) {
    case 'COMPLETED':
      return 'success';
    case 'CANCELED':
    case 'PENDING':
    case 'ERROR':
      return 'error';
  }
};

const getStatusLabel = (status: Donazione['status']): string => {
  switch (status) {
    case 'COMPLETED':
      return 'Pagato';
    case 'CANCELED':
    case 'PENDING':
    case 'ERROR':
      return 'Non pagato';
  }
};

export const DonazioneStatusChip = ({ status, show }: DonazioneStatusChipProps) => {
  const icon = useMemo(() => getStatusIcon(status), [status]);
  const color = useMemo(() => getStatusColor(status), [status]);
  const label = useMemo(() => getStatusLabel(status), [status]);

  if (!show) return null;

  return (
    <Chip
      variant="filled"
      color={color}
      // using deleteIcon instead of icon to align it right and with the right opacity
      deleteIcon={icon}
      onDelete={voidFunc}
      sx={{
        padding: '2px 2px',
        display: 'flex',
        '& .MuiChip-deleteIcon': {
          cursor: 'default',
          pointerEvents: 'none',
        },
      }}
      size="small"
      style={{ width: 'fit-content' }}
      label={label}
    />
  );
};
