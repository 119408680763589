import { Box, Button, CircularProgress, Dialog, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { usePolling } from '../../hooks/usePolling';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getPaymentStatus } from '../../api/getPaymentStatus';
import { useAuth } from '../auth/AuthProvider';
import CheckIcon from '@mui/icons-material/Check';
enum TransazioneStatusEnum {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}
type ConfirmModalProps = {
  link: string;
  onCancel: () => void;
  onComplete: () => void;
  onRetry: (donazioneId: number) => void;
  total: number;
  orderId: string;
};

const DEFAULT_POLLING_RATE = 7500;
const { REACT_APP_NEXI_POLLING_RATE: NEXI_POLLING_RATE = DEFAULT_POLLING_RATE } = process.env;
const POLLING_RATE = Number(NEXI_POLLING_RATE);

export const QrModal: React.FC<ConfirmModalProps> = ({ orderId, total, link, onComplete, onCancel, onRetry }) => {
  const { token } = useAuth();

  const [open, setOpen] = useState(true);
  useEffect(() => setOpen(true), []);

  const [pollingState, setPollingState] = useState<TransazioneStatusEnum>();
  useEffect(() => setPollingState(undefined), [link]);

  const [donazioneId, setDonazioneId] = useState<number>();
  useEffect(() => setDonazioneId(undefined), [orderId]);

  usePolling(
    async () => {
      const data = await getPaymentStatus({ orderId, token });
      if (data.transaction.status) {
        setPollingState(data.transaction.status);
        setDonazioneId(data.transaction.id_donazione);
      }
    },
    POLLING_RATE,
    pollingState === TransazioneStatusEnum.PENDING,
  );

  const copyToClipboard = useCopyToClipboard();

  return (
    <Dialog open={open} onClose={onCancel} style={{ margin: '0 auto' }}>
      <Stack p={2} gap={6} alignItems="center">
        {pollingState === TransazioneStatusEnum.COMPLETED && (
          <Stack gap={8} p={2} alignItems="center">
            <Box p={5} textAlign="center" bgcolor="#EDF7ED">
              Pagamento avvenuto con successo
            </Box>
            <Button
              variant="contained"
              startIcon={<CheckIcon />}
              sx={{ bgcolor: '#2E7D32', px: 5 }}
              onClick={() => {
                setOpen(false);
                onComplete();
              }}
            >
              OK
            </Button>
          </Stack>
        )}
        {pollingState === TransazioneStatusEnum.ERROR && (
          <Stack gap={8} p={2} alignItems="center">
            <Stack p={5} alignItems="center" borderRadius={2} gap={5} bgcolor="#FDEDED">
              <Box textAlign="center">
                Attenzione! <br />
                Pagamento non riuscito.
              </Box>
              <Box>Riprova più tardi</Box>
            </Stack>
            <Button variant="contained" onClick={() => donazioneId && onRetry(donazioneId)} color="error">
              Ritenta pagamento
            </Button>
          </Stack>
        )}

        {pollingState === TransazioneStatusEnum.PENDING && (
          <Stack alignItems="center" gap={5}>
            <CircularProgress />
            <Box fontWeight="bold">Attendi la conferma del pagamento</Box>
          </Stack>
        )}
        {!pollingState && (
          <Stack alignItems="center" gap={5}>
            <Box textAlign="center" fontWeight="bold">
              Pagamento tramite QR code
            </Box>

            <Stack direction="row" gap={2} justifyContent="space-between" width="100%">
              <Box fontWeight="bold">Totale</Box>
              <Box>{total.toFixed(2)} €</Box>
            </Stack>
            <QRCode value={link} />
            <Box textAlign="center">Vuoi condividere con gli amici il link del pagamento?</Box>
            <Button
              variant="contained"
              onClick={() => copyToClipboard(link)}
              startIcon={
                <Stack alignContent="center" bgcolor="#2E7D32" borderRadius="100%" p={1}>
                  <ContentCopyIcon />
                </Stack>
              }
              sx={{ bgcolor: '#66BB6A', borderRadius: 6 }}
            >
              copia e condividi
            </Button>
            <Stack direction="row" gap={2} p={1}>
              <Button
                fullWidth
                onClick={() => {
                  setPollingState(undefined);
                  onCancel();
                }}
                color="primary"
                variant="outlined"
              >
                Annulla
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  setPollingState(TransazioneStatusEnum.PENDING);
                }}
                startIcon={pollingState && <CircularProgress size="1rem" color="inherit" />}
                disabled={pollingState}
                color="primary"
                variant="contained"
              >
                Avanti
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Dialog>
  );
};
