/* eslint-disable max-lines */
import { Box } from '@mui/material';
import NavBar from '../components/commons/NavBar';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import SentimentVeryDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentVeryDissatisfiedOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import RendicontazioniButton from '../components/commons/RendicontazioniButton';
import { useQuery } from 'react-query';
import { useAuth } from '../components/auth/AuthProvider';
import { fetchSummary } from '../api/fetchSummary';
import { SkeletonReport } from '../components/commons/Skeleton';

export const RendicontazioneMenu = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const { data, isLoading } = useQuery(['getRendicontazione'], () => fetchSummary(token), {
    retry: 0,
    cacheTime: 0,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const rendicontazioneExists = () => {
    return data && Object.values(data).length > 0;
  };

  const navigateIfRendicontazioneExists = (path: string) => {
    if (!rendicontazioneExists() && !isLoading) {
      return;
    }
    navigate(path);
  };

  return (
    <div className="container-main" style={{ overflowY: 'scroll' }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
      <h1>Consuntivo punto distribuzione</h1>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        height="100%"
        padding={2}
        gap={2}
        overflow="y"
      >
        {isLoading ? (
          <SkeletonReport />
        ) : (
          <>
            <RendicontazioniButton
              onClick={() => navigate('summary')}
              label="Rendiconto - Allegato B"
              icon={<ReceiptLongOutlinedIcon fontSize="large" color="primary" />}
            />
            <RendicontazioniButton
              onClick={() => navigateIfRendicontazioneExists('ddt')}
              label={
                rendicontazioneExists()
                  ? 'Documento di trasporto'
                  : 'Crea e salva un Allegato B prima di usare questa funzione'
              }
              disabled={!rendicontazioneExists()}
              icon={<LocalShippingOutlinedIcon fontSize="large" color="primary" />}
            />
            <RendicontazioniButton
              onClick={() => navigateIfRendicontazioneExists('merce-danneggiata')}
              label={
                rendicontazioneExists()
                  ? 'Merce danneggiata'
                  : 'Crea e salva un Allegato B prima di usare questa funzione'
              }
              disabled={!rendicontazioneExists()}
              icon={<SentimentVeryDissatisfiedOutlinedIcon fontSize="large" color="primary" />}
            />
          </>
        )}
      </Box>
      <NavBar />
    </div>
  );
};
