/* eslint-disable no-console */
import { Alert } from '@mui/material';
import { Contact, SearchCriteria } from '../interface/contact';
import { ContactCard } from './ContactCard';

export const ContactCards = ({
  contacts,
  error,
  searchCriteria,
}: {
  searchCriteria: SearchCriteria;
  contacts?: Contact[];
  error: Error | null;
}) => {
  return contacts?.length ? (
    <div className="person-card-box ">
      {contacts.map((contact, i) => (
        <ContactCard searchCriteria={searchCriteria} contact={contact} key={i} />
      ))}
    </div>
  ) : error ? (
    <Alert severity="error"> Si è verificato un errore durante la ricerca. </Alert>
  ) : (
    <Alert severity="warning"> Non ci sono anagrafiche inserite in AIRC corrispondenti alla tua ricerca</Alert>
  );
};
