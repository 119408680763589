import React, { CSSProperties, ReactNode } from 'react';
import { Box } from '@mui/material';

interface RendicontazioniButtonProps {
  onClick: () => void;
  icon: React.ReactElement;
  label: string | ReactNode;
  disabled?: boolean;
}

const renderStyles = (
  isDisabled: boolean,
): {
  button: CSSProperties;
  label: CSSProperties;
} => ({
  button: {
    boxShadow: isDisabled ? 'none' : '2px 2px 6px 1px rgba(0, 0, 0, 0.15)',
    backgroundColor: isDisabled ? '#D3D3D3' : 'white',
  },
  label: {
    color: isDisabled ? 'red' : '#919191',
    fontSize: isDisabled ? '0.9rem' : '1.3rem',
  },
});

export default function RendicontazioniButton({ onClick, icon, label, disabled = false }: RendicontazioniButtonProps) {
  return (
    <div
      className="icon-button"
      onClick={onClick}
      style={{ height: 'clamp(100px, 20vh, 250px)', ...renderStyles(disabled).button }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
        <Box display="flex" justifyContent="center" alignItems="center" width={42} height={42}>
          {icon}
        </Box>
        <span
          style={{
            fontSize: '1.3rem',
            fontWeight: 500,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            lineClamp: 2,
            textAlign: 'center',
            ...renderStyles(disabled).label,
          }}
        >
          {label}
        </span>
      </Box>
    </div>
  );
}
