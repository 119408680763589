import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createDonazione } from '../api/createDonazione';

import { useCallback, useState } from 'react';
import { cancelPayByLink } from '../api/cancelPayByLink';
import { useAuth } from '../components/auth/AuthProvider';
import { useContact } from '../components/contacts/ContactProvider';
import { DonazioneForm } from '../components/donazioni/DonazioneForm';
import { QrModal } from '../components/donazioni/QrModal';
import { schemaUpsertDonazione } from '../components/validation/schemaUpsertDonazione';
import { NuovaDonazione, PayByLinkData } from '../interface/donazione';
import { getPayByLink } from '../api/getPayByLink';
const getTotalAmountEuro = (donazione: { prodotti: { quantita: number; importo: number }[] }): number =>
  donazione.prodotti.reduce((acc, next) => acc + next.importo, 0);

export const CreateDonazione = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { contact } = useContact();
  const location = useLocation();

  const createDonazioneForm = useForm({
    resolver: yupResolver(schemaUpsertDonazione),
    defaultValues: {
      metodo_pagamento: location.state?.receipt?.metodo_pagamento || 'contanti',
    },
  });
  const hasScannedReceipt = location.state?.receipt?.qty !== undefined;

  const { mutate: mutateCreate, isLoading } = useMutation<NuovaDonazione, Error, NuovaDonazione>(
    (data: NuovaDonazione) => {
      return createDonazione({ newDonazione: data, token, scansione_ricevuta: hasScannedReceipt });
    },
  );

  const cancelPayByLinkMutation = useMutation<any, Error, any>(cancelPayByLink, {
    onSuccess: () => {
      setPayByLinkData(null);
    },
    onError: () => setPayByLinkData(null),
  });

  const [payByLinkData, setPayByLinkData] = useState<PayByLinkData | null>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const onSubmit = (data: any) => {
    const newData = { ...data };
    delete newData.importo;

    mutateCreate(newData, {
      onSuccess: async (createdData) => {
        toast.success('Donazione creata con successo');
        setTotalAmount(getTotalAmountEuro(createdData));
        if (createdData.payByLinkData) {
          setPayByLinkData(createdData.payByLinkData);
        } else {
          navigate('/');
        }
      },
      onError: (error: Error) => {
        toast.error(error.message);
      },
    });
  };

  const onCancel = async () => {
    if (payByLinkData) {
      cancelPayByLinkMutation.mutate(
        {
          linkId: payByLinkData.linkId,
          token,
          orderId: payByLinkData.orderId,
        },
        {
          onSuccess: () => {
            toast.info("L'inserimento della donazione è stato annullato");
            navigate('/');
          },
        },
      );
    }
  };

  const retryPayByLinkMutation = useMutation<
    { data: { paymentLink: PayByLinkData }; orderId: string; correlationId: string },
    Error,
    any
  >(({ donazioneId }: { donazioneId: number }) => getPayByLink({ donazioneId, token }), {
    mutationKey: [token],
    onSuccess: ({ data: { paymentLink }, orderId }) => {
      setPayByLinkData({ ...paymentLink, orderId });
    },
    onError: () => {
      toast.error('Si è verificato un errore nella generazione del link di pagamento, riprova più tardi');
    },
  });

  const onRetryNewLink = useCallback(
    async (donazioneId: number) => {
      if (!payByLinkData) return;
      await cancelPayByLinkMutation.mutateAsync({
        linkId: payByLinkData.linkId,
        token,
        orderId: payByLinkData.orderId,
      });
      await retryPayByLinkMutation.mutateAsync({ donazioneId });
    },
    [cancelPayByLinkMutation, retryPayByLinkMutation, payByLinkData, token],
  );

  const onPayByLinkConfirm = () => {
    setPayByLinkData(null);
    navigate('/');
  };
  return (
    <FormProvider {...createDonazioneForm}>
      <DonazioneForm contact={contact} onSubmit={onSubmit} onCancel={onCancel} isLoading={isLoading} />
      {payByLinkData && (
        <QrModal
          orderId={payByLinkData.orderId}
          total={totalAmount}
          onComplete={onPayByLinkConfirm}
          link={payByLinkData.link}
          onCancel={onCancel}
          onRetry={onRetryNewLink}
        />
      )}
    </FormProvider>
  );
};
