import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Stack } from '@mui/system';
import moment from 'moment';
import { FC, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { segnalaDuplicato } from '../api/segnalaDuplicato';
import { useAuth } from '../components/auth/AuthProvider';
import { Contact, SearchCriteria } from '../interface/contact';
import { DuplicateModal } from './DuplicateModal';

export const ContactCard: FC<{ contact: Contact; searchCriteria: SearchCriteria }> = ({ contact, searchCriteria }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [duplicateModal, setDuplicateModal] = useState(false);
  const setNewLocationState = (location: Location) => {
    const state: any = {};

    if (location.state?.receipt) state.receipt = location.state.receipt;
    if (searchCriteria.codice_fiscale && searchCriteria.codice_fiscale !== '')
      state.codice_fiscale = searchCriteria.codice_fiscale;

    return state;
  };
  const { token } = useAuth();
  return (
    <>
      {duplicateModal && <DuplicateModal contact={contact} onClose={() => setDuplicateModal(false)} />}
      <div
        className="person-card cursor-pointer"
        onClick={() =>
          navigate(`/update-contact?id=${contact.id}`, {
            state: setNewLocationState(location),
          })
        }
      >
        <Grid2 container columns={12} sx={{ width: '100%' }}>
          <Grid2 xs={10}>
            <div>
              <h3>
                {contact.nome} {contact.cognome}
              </h3>
              <p>
                CF: <strong>{contact.codice_fiscale} </strong>
              </p>
              <p>
                Email: <strong>{contact.indirizzo_email}</strong>
              </p>
              <p>
                Data di nascita:{' '}
                <strong>{contact.data_nascita && moment(contact.data_nascita).format('DD/MM/YYYY')}</strong>
              </p>
              <p>
                Residenza:
                <strong>
                  {contact.citta} {contact.provincia}
                </strong>
              </p>
              <p>
                Indirizzo:
                <strong> {`${contact.indirizzo} ${contact.indirizzo_civico}`.trim()}</strong>
              </p>
              <p>
                Codice contatto: <strong>{contact.codice_contatto || ''}</strong>
              </p>
            </div>
          </Grid2>
          <Grid2 xs={2}>
            <Stack justifyContent="space-between" height="100%" alignItems="center">
              <ChevronRightIcon color="primary" />
              {process.env['REACT_APP_DUPLICATE_ENABLE'] && (
                <Tooltip title="Segnala duplicato">
                  <IconButton
                    sx={{ bgcolor: '#9C27B04D' }}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await segnalaDuplicato(contact.id!, token);
                      setDuplicateModal(true);
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Grid2>
        </Grid2>
      </div>
    </>
  );
};
