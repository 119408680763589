import { Donazione } from '../interface/donazione';

export const validatePaymentRetry = ({ metodo_pagamento, status }: Donazione) => {
  if (metodo_pagamento !== 'paybylink') {
    return 'Solo i pagamenti eseguiti tramite Pay By Link possono essere ritentati';
  }
  if (status === 'COMPLETED') {
    return 'La donazione risulta già pagata';
  }
  return void 0;
};
