import { useState, useEffect } from 'react';

export function usePolling<T>(fetchData: () => Promise<T>, interval: number, isActive: boolean): T | null {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    if (!isActive) return;

    let isMounted = true;
    const poll = async () => {
      try {
        const result = await fetchData();
        if (isMounted) setData(result);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    poll();
    const id = setInterval(poll, interval);

    return () => {
      isMounted = false;
      clearInterval(id);
    };
  }, [fetchData, interval, isActive]);

  return data;
}
