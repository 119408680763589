export function useCopyToClipboard() {
  const copyToClipboard = async (text: string) => {
    if (!text) return;
    await navigator.clipboard.writeText(text);
    const tooltip = document.getElementById('tooltip')?.children[0];
    if (tooltip) {
      tooltip.innerHTML = 'Copiato';
    }

    try {
      // await navigator.clipboard.writeText(text);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy: ', err);
    }
  };

  return copyToClipboard;
}
